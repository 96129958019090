import { useMemo } from 'react';
import find from 'lodash/find';

import { TaskMemberInvitesListItemCheckInSmartContractShareTask } from './TaskMemberInvitesListItemCheckInSmartContractShare.types';
import { SmartContractShareStatuses } from '../../../../../../smartContractShares/smartContractSharesTypes';
import { UpdateItemsCacheKeys } from '../../../../../../../types/updateItemsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { TaskCheckInToSmartContractShareButton } from '../../../../../../tasks/components/modalButtons/TaskCheckInToSmartContractShareButton';

import { getDefaultSmartContract } from '../../../../../../smartContracts/utils/getDefaultSmartContract';

import { tasksKeys } from '../../../../../../../locales/keys';

interface TaskMemberInvitesListItemCheckInSmartContractShareProps {
  task: TaskMemberInvitesListItemCheckInSmartContractShareTask;
  cacheKeys?: UpdateItemsCacheKeys;
}

function TaskMemberInvitesListItemCheckInSmartContractShare({
  task,
  cacheKeys
}: TaskMemberInvitesListItemCheckInSmartContractShareProps) {
  const currentUser = useCurrentUser();

  const selectedSmartContract = useMemo(
    () => getDefaultSmartContract(task.smartContracts, 'last'),
    [task.smartContracts]
  );

  const checkInSmartContractShareId = useMemo(
    () =>
      find(
        selectedSmartContract?.smartContractShares,
        (smartContractShare) =>
          smartContractShare.generalLedger?.company?.owner?.nanoId ===
            currentUser.nanoId &&
          (smartContractShare.status ===
            SmartContractShareStatuses.CHECKED_OUT ||
            smartContractShare.status === SmartContractShareStatuses.INVITED)
      )?.id,
    [currentUser.nanoId, selectedSmartContract]
  );

  return (
    <TaskCheckInToSmartContractShareButton
      checkInSmartContractShareId={checkInSmartContractShareId}
      smartContractId={selectedSmartContract?.id}
      cacheKeys={cacheKeys}
      className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      tooltipI18nText={tasksKeys.buttons.checkIn}
      icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
      iconClassName="h-5 w-5"
    />
  );
}

export default TaskMemberInvitesListItemCheckInSmartContractShare;
