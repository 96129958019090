import React, { useCallback, useState } from 'react';

import some from 'lodash/some';

import {
  TaskNanoID,
  TaskStatuses
} from '../../../../../../../../tasks/tasksTypes';

import {
  FETCH_SHOW_TASK_QUERY,
  FetchShowTaskQueryResponse
} from '../../../../../../../../tasks/queries/fetchShowTask.query';
import { FetchFinTaskMessagesQueryResponse } from '../../../../../../../../messages/queries/fetchFinTaskMessages.query';

import { useTask } from '../../../../../../../../tasks/hooks/useTask';
import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useMessagesSettings } from '../../../../../../../../messages/hooks/useMessagesSettings';
import { useTaskMessagesContentCreateMessage } from '../../../../../../../../tasks/components/content/TaskMessagesContent/hooks/useTaskMessagesContentCreateMessage';

import { TaskMessagesContentMessagesList } from '../../../../../../../../tasks/components/content/TaskMessagesContent/components/TaskMessagesContentMessagesList';
import { DashboardActiveTaskMessageHeader } from './components/DashboardActiveTaskMessageHeader';
import {
  ItemCreateMessageForm,
  ItemCreateMessageFormStateScope,
  useItemCreateMessageFormState
} from '../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../../../tasks/TaskCache';

import { MessagesPermissions } from '../../../../../../../../messages/messagesConstants';
import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';

interface DashboardActiveTaskMessagesProps {
  taskNanoId?: TaskNanoID;
}

function DashboardActiveTaskMessages({
  taskNanoId
}: DashboardActiveTaskMessagesProps) {
  const { messagesSettings, fetchedMessageSettings } = useMessagesSettings();

  const [pinnedMessagesView, setPinnedMessagesView] = useState<boolean>(false);

  const togglePinnedMessagesView = useCallback<() => void>(
    () => setPinnedMessagesView((prevState) => !prevState),
    []
  );

  const currentUser = useCurrentUser();

  const { task, taskError, taskFetched, taskIsPlaceholderData } =
    useTask<FetchShowTaskQueryResponse>({
      tasks: [],
      cacheKey: TaskCache.activeTaskCacheKey(taskNanoId),
      query: FETCH_SHOW_TASK_QUERY,
      uuid: taskNanoId,
      options: {
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  const {
    createMessageStateKey,
    messageInitialValues,
    handleAfterCreateMessage,
    handleCreateMessageFormStateChange,
    handleSetRepliedMessageId
  } = useItemCreateMessageFormState({
    scope: ItemCreateMessageFormStateScope.TASK_OR_PROJECT
  });

  const {
    createMessageInTaskLoading,
    showClosedTaskMessageForm,
    sendingMessages,
    handleCreateMessageInTask,
    handleFetchMessagesSuccess,
    handleRemoveSendingMessage
  } = useTaskMessagesContentCreateMessage<FetchFinTaskMessagesQueryResponse>({
    task,
    onAfterCreateMessage: handleAfterCreateMessage
  });

  const isTaskMember = some(task?.members, { uuid: currentUser.uuid });

  const isTaskClosed =
    task &&
    (task.status === TaskStatuses.DONE ||
      task.status === TaskStatuses.CANCELED);

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      {taskNanoId ? (
        <div className="h-full flex flex-col-reverse">
          <AlertMessage addClassName="mt-4" message={taskError} />
          <LoadingSkeleton loaded={taskFetched || taskIsPlaceholderData}>
            {(!pinnedMessagesView && task && task.project && !isTaskClosed) ||
            (!pinnedMessagesView &&
              task &&
              task.project &&
              isTaskClosed &&
              showClosedTaskMessageForm) ? (
              <ItemCreateMessageForm
                key={createMessageStateKey}
                isLoading={createMessageInTaskLoading}
                projectNanoId={task.project.nanoId}
                messageInitialValues={messageInitialValues}
                withFullscreenDropzone={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
                )}
                withClientLocalTime={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CLIENT_LOCAL_TIME
                )}
                withArchivizerLocalTime={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_ARCHIVIZER_TEAM_LOCAL_TIME
                )}
                withPasteFiles={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_PASTE_FILES
                )}
                withVisibleForClientsButton={currentUser.hasPermissions(
                  MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON
                )}
                withAttachProductsButton
                withAttachLifestylesButton
                withAttachMaterialsButton={currentUser.hasPermissions(
                  MessagesPermissions.READ_MESSAGE_ATTACH_MATERIALS_BUTTON
                )}
                onChange={handleCreateMessageFormStateChange}
                onSubmit={handleCreateMessageInTask}
                teamNanoId={task.project.team?.nanoId}
                hiddenTask={
                  currentUser.hasPermissions(
                    TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_HIDDEN_TASK_ALERT
                  ) && !task.visibleForClient
                }
                sendToTaskId={task.id}
                withLexical
                withMarkdownHelp
              />
            ) : null}

            {task && task.project && fetchedMessageSettings ? (
              <TaskMessagesContentMessagesList
                taskNanoId={task.nanoId}
                projectUuid={task.project.uuid}
                taskUuid={task.uuid}
                onReplyMessage={handleSetRepliedMessageId}
                sendingMessages={sendingMessages}
                onFetchMessagesSuccess={handleFetchMessagesSuccess}
                onRemoveSendingMessage={handleRemoveSendingMessage}
                pinnedMessagesView={pinnedMessagesView}
                togglePinnedMessagesView={togglePinnedMessagesView}
                withWhiteboardMessages={messagesSettings.withWhiteboardMessages}
              />
            ) : null}

            <DashboardActiveTaskMessageHeader
              task={task}
              isTaskMember={isTaskMember}
            />
          </LoadingSkeleton>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardActiveTaskMessages;
