import { gql } from 'graphql-request';

import {
  ProjectID,
  ProjectUUID,
  ProjectName,
  ProjectStatus,
  ProjectNanoID,
  ProjectLastMessageCreatedAt,
  ProjectLastMessageBody,
  ProjectLastMessageUserID,
  ProjectLastMessageUserNanoID,
  ProjectLastMessageUserFullName,
  ProjectLastMessageUserImageUUID,
  ProjectLastMessageUserImageFile,
  ProjectLastActivity
} from '../projectsTypes';

export interface FetchProjectsDashboardQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  name: ProjectName;
  status: ProjectStatus;
  lastActivity: ProjectLastActivity;
  lastMessage: {
    createdAt: ProjectLastMessageCreatedAt;
    body: ProjectLastMessageBody;
    user: {
      id: ProjectLastMessageUserID;
      nanoId: ProjectLastMessageUserNanoID;
      fullName: ProjectLastMessageUserFullName;
      image: {
        uuid: ProjectLastMessageUserImageUUID;
        file: ProjectLastMessageUserImageFile;
      };
    };
  };
}

export const FETCH_PROJECTS_DASHBOARD_QUERY = gql`
  query IndexProjects(
    $filters: ProjectsFilters
    $sort: [ProjectsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projects(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        name
        status
        lastActivity
        createdAt
        lastMessage {
          createdAt
          body
          user {
            id
            nanoId
            fullName
            image {
              uuid
              file
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
