import React, { Fragment, useMemo } from 'react';

import { TaskNanoID } from '../../../../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../../../../assets/icons/types';

import {
  FETCH_TASK_SMART_CONTRACTS_QUERY,
  FetchTaskSmartContractsQueryResponse
} from '../../../../../../../../../../../smartContracts/queries/fetchTaskSmartContracts.query';

import { useFinPaginatedSmartContracts } from '../../../../../../../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';
import { useCurrentUser } from '../../../../../../../../../../../../auth/hooks/useAuth';

import { TaskCheckInButton } from '../../../../../../../../../../../tasks/components/modalButtons/TaskCheckInButton';
import { TaskFinCheckInButton } from '../../../../../../../../../../../tasks/components/modalButtons/TaskFinCheckInButton';

import { CheckPermissions } from '../../../../../../../../../../../../helpers/CheckPermissions';

import { getDefaultSmartContract } from '../../../../../../../../../../../smartContracts/utils/getDefaultSmartContract';

import { SmartContractSharesPermissions } from '../../../../../../../../../../../smartContractShares/smartContractSharesConstants';
import { TasksPermissions } from '../../../../../../../../../../../tasks/tasksConstants';

import { SmartContractCache } from '../../../../../../../../../../../smartContracts/SmartContractCache';
import { TaskMemberInviteCache } from '../../../../../../../../../../../taskMemberInvites/TaskMemberInviteCache';

interface DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps {
  taskNanoId: TaskNanoID;
}

function DashboardActiveTaskMessageHeaderTaskCheckInWrapper({
  taskNanoId
}: DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps) {
  const currentUser = useCurrentUser();

  const { smartContracts } =
    useFinPaginatedSmartContracts<FetchTaskSmartContractsQueryResponse>({
      query: FETCH_TASK_SMART_CONTRACTS_QUERY,
      cacheKey: SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
      initialFilters: { taskNanoId: { eq: taskNanoId } },
      options: {
        withoutPrefetch: true,
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  const selectedSmartContract = useMemo<FetchTaskSmartContractsQueryResponse>(
    () => getDefaultSmartContract(smartContracts),
    [smartContracts]
  );

  if (!selectedSmartContract) {
    return null;
  }

  return (
    <Fragment>
      <CheckPermissions action={TasksPermissions.READ_CHECK_IN_TASK_BUTTON}>
        <TaskCheckInButton
          taskNanoId={taskNanoId}
          smartContractNanoId={selectedSmartContract?.nanoId}
          icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          iconClassName="h-6 w-6"
          cacheKeys={[
            TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
              currentUser.nanoId
            )
          ]}
        />
      </CheckPermissions>

      <CheckPermissions
        action={
          SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON
        }
      >
        <TaskFinCheckInButton
          taskNanoId={taskNanoId}
          smartContractId={selectedSmartContract?.id}
          icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          iconClassName="h-6 w-6"
          cacheKeys={[
            TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
              currentUser.nanoId
            )
          ]}
        />
      </CheckPermissions>
    </Fragment>
  );
}

export default DashboardActiveTaskMessageHeaderTaskCheckInWrapper;
