import React, { useCallback, useState } from 'react';

import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';

import {
  FETCH_SHOW_PROJECT_QUERY,
  FetchShowProjectQueryResponse
} from '../../../../../../../../projects/queries/fetchShowProject.query';
import { FetchFinProjectMessagesQueryResponse } from '../../../../../../../../messages/queries/fetchFinProjectMessages.query';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useMessagesSettings } from '../../../../../../../../messages/hooks/useMessagesSettings';
import { useProject } from '../../../../../../../../projects/hooks/useProject';
import { useProjectMessagesContentCreateMessage } from '../../../../../../../../projects/components/content/ProjectMessagesContent/hooks/useProjectMessagesContentCreateMessage';

import { DashboardActiveTaskProjectMessagesHeader } from './components/DashboardActiveTaskProjectMessagesHeader';
import { ProjectMessagesContentMessagesList } from '../../../../../../../../projects/components/content/ProjectMessagesContent/components/ProjectMessagesContentMessagesList';
import {
  ItemCreateMessageForm,
  ItemCreateMessageFormStateScope,
  useItemCreateMessageFormState
} from '../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { MessagesPermissions } from '../../../../../../../../messages/messagesConstants';
import { ProjectsPermissions } from '../../../../../../../../projects/projectsConstants';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';

interface DashboardActiveTaskProjectMessagesProps {
  projectNanoId?: ProjectNanoID;
}

function DashboardActiveTaskProjectMessages({
  projectNanoId
}: DashboardActiveTaskProjectMessagesProps) {
  const { messagesSettings, fetchedMessageSettings } = useMessagesSettings();

  const [pinnedMessagesView, setPinnedMessagesView] = useState<boolean>(false);

  const togglePinnedMessagesView = useCallback<() => void>(
    () => setPinnedMessagesView((prevState) => !prevState),
    []
  );

  const currentUser = useCurrentUser();

  const { project, projectError, projectFetched, projectIsPlaceholderData } =
    useProject<FetchShowProjectQueryResponse>({
      projects: [],
      cacheKey: ProjectCache.showCacheKey(),
      query: FETCH_SHOW_PROJECT_QUERY,
      uuid: projectNanoId,
      options: {
        enabled: !!projectNanoId,
        enabledPlaceholder: !!projectNanoId
      }
    });

  const {
    createMessageStateKey,
    messageInitialValues,
    handleAfterCreateMessage,
    handleCreateMessageFormStateChange,
    handleSetRepliedMessageId
  } = useItemCreateMessageFormState({
    scope: ItemCreateMessageFormStateScope.TASK_OR_PROJECT
  });

  const {
    createMessageInProjectLoading,
    sendingMessages,
    handleCreateMessageInProject,
    handleFetchMessagesSuccess,
    handleRemoveSendingMessage
  } = useProjectMessagesContentCreateMessage<FetchFinProjectMessagesQueryResponse>(
    {
      project,
      onAfterCreateMessage: handleAfterCreateMessage
    }
  );

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      {projectNanoId ? (
        <div className="h-full flex flex-col-reverse">
          <AlertMessage addClassName="mt-4" message={projectError} />
          <LoadingSkeleton loaded={projectFetched || projectIsPlaceholderData}>
            {!pinnedMessagesView && project ? (
              <ItemCreateMessageForm
                key={createMessageStateKey}
                isLoading={createMessageInProjectLoading}
                messageInitialValues={messageInitialValues}
                projectNanoId={projectNanoId}
                withFullscreenDropzone={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
                )}
                withClientLocalTime={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CLIENT_LOCAL_TIME
                )}
                withArchivizerLocalTime={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_ARCHIVIZER_TEAM_LOCAL_TIME
                )}
                withPasteFiles={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_PASTE_FILES
                )}
                withVisibleForClientsButton={currentUser.hasPermissions(
                  MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON
                )}
                withAttachProductsButton
                withAttachLifestylesButton
                withAttachMaterialsButton={currentUser.hasPermissions(
                  MessagesPermissions.READ_MESSAGE_ATTACH_MATERIALS_BUTTON
                )}
                onChange={handleCreateMessageFormStateChange}
                onSubmit={handleCreateMessageInProject}
                teamNanoId={project.team?.nanoId}
                sendToProjectId={project.id}
                withLexical
                withMarkdownHelp
              />
            ) : null}

            {project && fetchedMessageSettings ? (
              <ProjectMessagesContentMessagesList
                projectNanoId={projectNanoId}
                projectUuid={project.uuid}
                sendingMessages={sendingMessages}
                onReplyMessage={handleSetRepliedMessageId}
                onFetchMessagesSuccess={handleFetchMessagesSuccess}
                onRemoveSendingMessage={handleRemoveSendingMessage}
                pinnedMessagesView={pinnedMessagesView}
                togglePinnedMessagesView={togglePinnedMessagesView}
                withWhiteboardMessages={messagesSettings.withWhiteboardMessages}
              />
            ) : null}

            <DashboardActiveTaskProjectMessagesHeader project={project} />
          </LoadingSkeleton>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardActiveTaskProjectMessages;
