import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';
import {
  FetchSmartContractSharesCacheKey,
  FetchFinSmartContractSharesFilters,
  FetchSmartContractSharesSort,
  FetchSmartContractSharesPage,
  FetchSmartContractSharesLimit,
  FetchSmartContractSharesGqlQuery
} from '../../smartContractSharesTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_SMART_CONTRACT_SHARES_FILTERS,
  INITIAL_SMART_CONTRACT_SHARES_LIMIT,
  INITIAL_SMART_CONTRACT_SHARES_PAGE,
  INITIAL_SMART_CONTRACT_SHARES_SORT
} from '../../smartContractSharesConstants';

interface SmartContractSharesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface SmartContractSharesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface SmartContractSharesDefaultOptions {
  cacheKey: FetchSmartContractSharesCacheKey;
  query: FetchSmartContractSharesGqlQuery;
  initialFilters?: FetchFinSmartContractSharesFilters;
  initialSort?: FetchSmartContractSharesSort;
  initialPage?: FetchSmartContractSharesPage;
  initialLimit?: FetchSmartContractSharesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

type SmartContractSharesOptions = SmartContractSharesDefaultOptions &
  (
    | SmartContractSharesWithPrefetchItemOptions
    | SmartContractSharesWithoutPrefetchItemOptions
  );

const scope = 'smartContractShares';

function useFinPaginatedSmartContractShares<FetchSmartContractSharesType>({
  cacheKey,
  initialFilters = INITIAL_SMART_CONTRACT_SHARES_FILTERS,
  initialSort = INITIAL_SMART_CONTRACT_SHARES_SORT,
  initialPage = INITIAL_SMART_CONTRACT_SHARES_PAGE,
  initialLimit = INITIAL_SMART_CONTRACT_SHARES_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  query,
  options = {}
}: SmartContractSharesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    updateItemsCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useFinIndexQuery<FetchSmartContractSharesType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    smartContractSharesData: data,
    smartContractShares: items,
    smartContractSharesError: itemsError,
    smartContractSharesErrorMessage: itemsErrorMessage,
    smartContractSharesTotalCount: itemsTotalCount,
    smartContractSharesFetched: isFetched,
    smartContractSharesLoading: isLoading,
    smartContractSharesIsPlaceholderData: isPlaceholderData,
    smartContractSharesFilters: currentFilters,
    smartContractSharesSort: currentSort,
    smartContractSharesPage: currentPage,
    smartContractSharesLimit: currentLimit,
    updateSmartContractCache: updateItemCache,
    updateSmartContractSharesCache: updateItemsCache,
    filterSmartContractShares: filterItems,
    changeSmartContractSharesFilters: changeItemsFilters,
    clearSmartContractSharesFilters: clearItemsFilters,
    sortSmartContractShares: sortItems,
    paginateSmartContractShares: paginateItems,
    limitSmartContractShares: limitItems,
    prefetchSmartContractShares: prefetchItems,
    prefetchSmartContract: prefetchItem
  };
}

export default useFinPaginatedSmartContractShares;
