import { gql } from 'graphql-request';

import {
  SmartContractShareCreatedAt,
  SmartContractShareGeneralLedgerCompanyID,
  SmartContractShareGeneralLedgerCompanyImageFile,
  SmartContractShareGeneralLedgerCompanyName,
  SmartContractShareGeneralLedgerCompanyNanoID,
  SmartContractShareGeneralLedgerCompanyOwnerID,
  SmartContractShareGeneralLedgerCompanyOwnerNanoID,
  SmartContractShareGeneralLedgerID,
  SmartContractShareGeneralLedgerName,
  SmartContractShareID,
  SmartContractShareIteration,
  SmartContractShareShare,
  SmartContractShareShareType,
  SmartContractShareSmartContractID,
  SmartContractShareStatus,
  SmartContractShareUUID,
  SmartContractShareUpdatedAt,
  SmartContractShareUserFullName,
  SmartContractShareUserID,
  SmartContractShareUserName,
  SmartContractShareUserNanoID,
  SmartContractShareUserSurname,
  SmartContractShareUserUUID
} from '../smartContractSharesTypes';

interface FetchSmartContractShareUser {
  id: SmartContractShareUserID;
  uuid: SmartContractShareUserUUID;
  nanoId: SmartContractShareUserNanoID;
  name: SmartContractShareUserName;
  surname: SmartContractShareUserSurname;
  fullName: SmartContractShareUserFullName;
}

interface FetchSmartContractShareGeneralLedgerCompany {
  id: SmartContractShareGeneralLedgerCompanyID;
  nanoId: SmartContractShareGeneralLedgerCompanyNanoID;
  name: SmartContractShareGeneralLedgerCompanyName;
  image: {
    file: SmartContractShareGeneralLedgerCompanyImageFile;
  };
  owner: {
    id: SmartContractShareGeneralLedgerCompanyOwnerID;
    nanoId: SmartContractShareGeneralLedgerCompanyOwnerNanoID;
  };
}

interface FetchSmartContractShareGeneralLedger {
  id: SmartContractShareGeneralLedgerID;
  name: SmartContractShareGeneralLedgerName;
  companyId: SmartContractShareGeneralLedgerCompanyID;
  company: FetchSmartContractShareGeneralLedgerCompany;
}

export interface FetchTaskSmartContractShareQueryResponse {
  id: SmartContractShareID;
  uuid: SmartContractShareUUID;
  createdAt: SmartContractShareCreatedAt;
  updatedAt: SmartContractShareUpdatedAt;
  iteration: SmartContractShareIteration;
  status: SmartContractShareStatus;
  share: SmartContractShareShare;
  shareType: SmartContractShareShareType;
  smartContractId: SmartContractShareSmartContractID;
  generalLedgerId: SmartContractShareGeneralLedgerID;
  user: FetchSmartContractShareUser;
  generalLedger: FetchSmartContractShareGeneralLedger;
}

export const FETCH_SMART_CONTRACT_SHARES_QUERY = gql`
  query SmartContractShares(
    $filters: SmartContractSharesFilters
    $sort: [SmartContractSharesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContractShares(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        updatedAt
        iteration
        status
        share
        shareType
        smartContractId
        generalLedgerId
        user {
          id
          uuid
          nanoId
          name
          surname
          fullName
        }
        generalLedger {
          id
          name
          companyId
          company {
            id
            nanoId
            name
            image {
              file
            }
            owner {
              id
              nanoId
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
